<template>
  <div class="phonePage">
    <div class="navBar">
      <div @click="$router.go(-1)" class="back">
        <svg-icon class="leftBtn" iconClass="back"></svg-icon>
        <span>返回</span>
      </div>
      <div class="title">手机号</div>
      <div class="rightBtn"></div>
    </div>
    <div class="phoneIconBox">
      <svg-icon class="phoneIcon" iconClass="phone"></svg-icon>
    </div>
    <div class="phone">已绑定手机号：{{ $route.query.phone }}</div>
    <div class="tip">你可使用注册的手机号登录</div>
    <router-link tag="div" to="bindPhone" class="changeBind">更换绑定</router-link>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.phonePage {
  .navBar {
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    background-color: #fff;
    box-sizing: border-box;
    border-bottom: 1px solid rgb(217, 217, 217);
    .back {
      width: 80px;
      display: flex;
      align-items: center;

      .leftBtn {
        width: 24px;
        height: 24px;
        font-size: 24px;
      }

      span {
        font-size: 18px;
      }
    }

    .title {
      flex: 1;
      font-size: 18px;
      text-align: center;
      color: #000;
    }

    .rightBtn {
      width: 80px;
    }
  }
  .phoneIconBox {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 40px 0 28px;
    .phoneIcon {
      width: 90px;
      height: 128px;
    }
  }
  .phone {
    text-align: center;
    font-size: 16px;
  }
  .tip {
    font-size: 14px;
    color: #ffffff50;
    text-align: center;
    margin-top: 6px;
  }
  .changeBind {
    margin: 0 16px;
    height: 44px;
    line-height: 44px;
    font-size: 15px;
    border-radius: 4px;
    margin-top: 72px;
    text-align: center;
    color: #fff;
    background: rgb(255, 103, 143);
  }
}
</style>
